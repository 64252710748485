import React, { useContext, useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import FrontOfficeTheme from '../style/globalTheme'
import Avatar from '@mui/material/Avatar'


import { Location } from '@reach/router'
import queryString from 'query-string'
import PropTypes from "prop-types"
import { AuthContext } from "../context/general"
import { grey, red, green, indigo } from '@mui/material/colors'
import Layout from "../components/layout"

const App = ({ search }) => {

  const [mounted, setMounted] = useState(false)
  const [newVimeoId, setNewVimeoId] = useState(null)


  useEffect(() => {

    setMounted(true)

  }, [])



  const classes = useStyles()
  const { authState, authActions } = useContext(AuthContext)

  const replays = authState.replays

  // const replays = [
  //   {
  //     id: '6675aefe-9af0-4931-b4f6-cf7ec7e6df27',
  //     imageKey: '69a01a59-5ea7-4936-8d7a-183df273de5e',
  //     replayKey: "681491699",
  //     title1: 'Jésus à la lumière des manuscrits de Qumrân',
  //     imageETag300: "feb761fa2fd3eec7bdeaefccbfc33024"
  //   },
  //   {
  //     imageKey: "af8e291c-41e9-4c13-a15b-0dd508c35196",
  //     replayKey: "681491699",
  //     title1: 'Jésus à la lumière des manuscrits de Qumrân',
  //     imageETag300: "b3bf3797f311449e51aaa1dde316c173"
  //   }
  // ]

  const eventKey = search?.eventKey
  const startEvent = replays.find(elem => elem.id == eventKey)

  const vimeoId = startEvent?.replayKey
  const displayVimeoId = newVimeoId || vimeoId
  const newEvent = replays.find(elem => elem.replayKey == displayVimeoId)




  const SingleComponent = ({ event, newVimeoId, setNewVimeoId }) => {





    return <Box display='flex' flexDirection='row' padding='5px' color={grey[100]} bgcolor={grey[800]} height='50px' width='100%' 
      sx={[
        {
          transition: 'background-color 0.3s',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: grey[600],
            color: 'white'
          },
        }

      ]}


      onClick={() => {
        console.log('@CLICK')
        setNewVimeoId(event.replayKey)
      }}>
      <Avatar sx={{width: 35, height: 35}} style={{ backgroundColor: grey[200] }}>
        <img style={{ marginTop: '10px', width: '50px', height: '50px', objectFit: 'cover' }} src={`https://pekoia.com/static/L${event.imageKey}-300-${event.imageETag300}.jpg`} alt={event.title1} />
      </Avatar>
      {/* <Box width='50px' height='50px' >
        <img style={{ width: '50px', height: '50px', objectFit: 'cover' }} src={`https://pekoia.com/static/L${event.imageKey}-300-${event.imageETag300}.jpg`} alt={event.title1} />

      </Box> */}
      <Box width='300px' padding='5px 10px 0 10px'>
        <Typography style={{ fontSize: '0.7rem', lineHeight: '0.7rem', fontWeight: 500 }}>
          {event.title1}
        </Typography>
      </Box>


    </Box>
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={FrontOfficeTheme}>
        <Layout>
          <Box className={classes.root} >
            <Box className={classes.videoContainer} bgcolor='black'>
              {mounted && <iframe src={`https://player.vimeo.com/video/${displayVimeoId}`}
                className={classes.iFrame} frameBorder={0} allow="autoplay; fullscreen" title="Pekoia Test" allowFullScreen></iframe>}
              <Box className={classes.title}>
                <Typography style={{ color: 'white', fontSize: '1.2rem', fontWeight: 600 }}>
                  {newEvent?.title1}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.thumbnailsContainer}>
              {replays.map((event, index) => <SingleComponent key={'thumbnail' + index} event={event} newVimeoId={newVimeoId} setNewVimeoId={setNewVimeoId} />)}
            </Box>




          </Box>    </Layout>
      </ThemeProvider>
    </StyledEngineProvider>

  )


}




const CustomQueryStringComponent = ({ search }) => {
  return (<App search={search} />)
}

CustomQueryStringComponent.propTypes = {
  search: PropTypes.object,
}



const withLocation = ComponentToWrap => props => (
  <Location>
    {({ location, navigate }) => (
      <ComponentToWrap
        {...props}
        location={location}
        navigate={navigate}
        search={location.search ? queryString.parse(location.search) : {}}
      />
    )}
  </Location>
)

export default withLocation(App)







const useStyles = makeStyles(theme => ({

  notificationItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'black'
    }

  },

  root: {

    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: '0 20px 0 20px',

    },



    backgroundColor: 'black'
  },

  title: {
    paddingLeft: '10px',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      bottom: -40,


    },

  },

  iFrame: {
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'black',
      position: 'absolute',
      top: 0,
      left: 0,


    },
  },
  videoContainer: {

    width: '100vw',
    height: '56.25vw',
   
    [theme.breakpoints.up('md')]: {
      width: 'calc(100vw - 440px)',
      height: 'calc(56.25vw - 247.5px)',
      position: 'relative'


    },




  },
  thumbnailsContainer: {
    width: '100%',
    marginTop: '100px',
    overflowX: 'hidden',
    overflowY: 'visible',
    height: '90vh',
    backgroundColor: grey[900],
  
    padding: '0 10px 0 10px',

    [theme.breakpoints.up('md')]: {
      width: '400px',
      marginTop: '0px',
      height: '100vh',
      overflowX: 'hidden',
      overflowY: 'scroll',



      padding: '0 20px 0 20px',

    },

  }

}));


